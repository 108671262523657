import React, { useEffect, useState } from 'react'
import Helmet from 'react-helmet'
import IntroOverlay from '../components/intro-overlay'
import PortableText from '../components/portableText'
import ReleasesGrid from '../components/releases-grid'
import ReleasesGridMobile from '../components/releases-grid-mobile'

const Page = props => {
  const { _rawIntroText, _rawGrid, _rawFooterText, _rawFooterTextMobile } = props
  const [introHidden, setIntroHidden] = useState(false)
  const grid = _rawGrid
  const textLogo = `<Shape>`

  const hideIntro = () => {
    setIntroHidden(true)
  }

  return (
    <>
      <Helmet>{!introHidden && <html className="is-intro-in"></html>}</Helmet>
      <IntroOverlay hideIntro={hideIntro} introHidden={introHidden} />
      <div className="page">
        <h1 className="page__logo page__logo--desktop">{textLogo}</h1>
        <div className="page__intro">
          <h1 className="page__logo page__logo--mobile">{textLogo}</h1>
          <PortableText blocks={_rawIntroText} />
        </div>
        {grid && <ReleasesGrid grid={grid} introHidden={introHidden} />}
        {grid && <ReleasesGridMobile grid={grid} />}
        <div className="page__footer page__footer--desktop">
          <PortableText blocks={_rawFooterText} />
        </div>
        <div className="page__footer page__footer--mobile">
          <PortableText blocks={_rawFooterTextMobile} />
        </div>
      </div>
    </>
  )
}

export default Page
