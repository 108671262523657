import React, { useRef, useState, useEffect } from 'react'
import Release from './release'
import ImageLoader from './image-loader'
import cx from 'classnames'

// transitions
const imgInDuration = 400
const imgOutDuration = 800
const imgOutDelay = 200
const tIn = `opacity ${imgInDuration}ms cubic-bezier(0.445, 0.050, 0.550, 0.950)`
const tOut = `opacity ${imgOutDuration}ms cubic-bezier(0.445, 0.050, 0.550, 0.950) ${imgOutDelay}ms`

const ReleasesGrid = props => {
  const { grid, introHidden } = props
  const [currentImageId, setCurrentImageId] = useState(null)
  const bgImageContainerRef = useRef()

  const backgroundImages = grid
    .map(row => row.columns && row.columns.filter(col => col.release))
    .flat()
    .map(_col => _col.release.backgroundImage)

  const onMouseEnterReleaseContainer = release => {
    bgImageContainerRef.current.style.transition = tIn
    if (release.backgroundImage.asset) {
      setCurrentImageId(release.backgroundImage.asset._id)
    }
  }

  const onMouseLeaveReleaseContainer = () => {
    bgImageContainerRef.current.style.transition = tOut
    setCurrentImageId(null)
  }

  const initRandomBg = () => {
    const random = Math.floor(Math.random() * backgroundImages.length)
    const randomImg = backgroundImages[random]
    if (randomImg.asset) {
      setCurrentImageId(backgroundImages[random].asset._id)
    }
  }

  useEffect(() => {
    initRandomBg()
  }, [])

  return (
    <div className="releases-grid is-hidden-mobile">
      <div className="releases-grid__grid">
        {grid.map((row, rowI) => {
          return (
            <div className={`releases-grid__row is-flex is-${rowI}`} key={row._key}>
              {row.columns &&
                row.columns.map((col, colI) => {
                  const { release, contentAlignment, contentOffsetY, contentOffsetX, _key } = col
                  const contentAlignmentClass = contentAlignment
                    ? `is-content-${contentAlignment}`
                    : ``
                  const offsetX = contentOffsetX ? contentOffsetX : 0
                  const offsetY = contentOffsetY ? contentOffsetY : 0
                  const offsetStyle = { transform: `translate(${offsetX}%, ${offsetY}%)` }
                  return (
                    <div className={`releases-grid__column ${contentAlignmentClass}`} key={_key}>
                      <div className="releases-grid__column-inner" style={offsetStyle}>
                        {release && release.backgroundImage && (
                          <div
                            className="releases-grid__release-container"
                            onMouseEnter={() => onMouseEnterReleaseContainer(release)}
                            onMouseLeave={() => onMouseLeaveReleaseContainer()}
                          >
                            <Release
                              {...release}
                              aniDelayOffset={rowI + colI}
                              introHidden={introHidden}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  )
                })}
            </div>
          )
        })}
      </div>
      <div className="releases-grid__bg-image-container" ref={bgImageContainerRef}>
        {backgroundImages.map((image, i) => {
          if (image.asset) {
            const isIn = image.asset.id === currentImageId
            return (
              <div
                className={cx('releases-grid__bg-image', {
                  'is-in': isIn,
                })}
                key={image.asset._id}
              >
                <ImageLoader
                  imageData={image}
                  widths={[750, 1024, 2400, 1400, 1920, 2400, 2560]}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                  }}
                />
              </div>
            )
          }
        })}
      </div>
    </div>
  )
}

export default ReleasesGrid
