import React from 'react'
import MainImage from './MainImage'

const serializers = {
  marks: {
    link: ({ mark, children }) => {
      const { blank, href } = mark
      if (blank !== false) {
        return (
          <a href={href} target="_blank" rel="noopener">
            {children}
          </a>
        )
      } else {
        return <a href={href}>{children}</a>
      }
    },
  },
  types: {
    mainImage: ({ node }) => <MainImage mainImage={node} />,
  },
}

export default serializers
