import React from 'react'
import Release from '../components/release'

const ReleasesGridMobile = props => {
  const { grid } = props
  const releaseCols = grid.map(row => row.columns && row.columns.filter(col => col.release)).flat()

  return (
    <div className="releases-grid-mobile">
      <div className="releases-grid-mobile__grid">
        {releaseCols.map((releaseCol, i) => (
          <div className="releases-grid-mobile__row" key={releaseCol._key}>
            <div className="releases-grid-mobile__column">
              <Release {...releaseCol.release} mobView />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default ReleasesGridMobile
