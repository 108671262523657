import React from 'react'
import { graphql } from 'gatsby'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../components/layout'
import Page from '../components/page'

export const query = graphql`
  query PageTemplateQuery($id: String!) {
    route: sanityRoute(id: { eq: $id }) {
      slug {
        current
      }
      useSiteTitle
      page {
        ...PageInfo
      }
    }
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
      openGraph {
        image {
          ...SanityImage
        }
      }
    }
  }
`

const PageTemplate = props => {
  const { data, errors } = props

  if (errors) {
    return <GraphQLErrorList errors={errors} />
  }

  const site = (data || {}).site

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }
  const page = data.page || data.route.page
  const pageTitle = data.route && !data.route.useSiteTitle && page.title

  return (
    <>
      <Layout>
        <SEO title={pageTitle} description={site.description} keywords={site.keywords} />
        <Page {...page} />
      </Layout>
    </>
  )
}

export default PageTemplate
