import React from 'react'
import '../styles/styles.scss'

const Layout = props => {
  const { children } = props

  return (
    <>
      <div className="content-wrapper">{children}</div>
    </>
  )
}

export default Layout
