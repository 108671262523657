import React, { useEffect } from 'react'
import ShapeLogo from '../images/logo/shape-logo.svg'
import cx from 'classnames'

const IntroOverlay = props => {
  const { introHidden } = props
  const onClick = () => {
    props.hideIntro()
  }

  const onWheel = () => {
    props.hideIntro()
  }

  useEffect(() => {
    setTimeout(() => {
      props.hideIntro()
    }, 1500)
  }, [])

  return (
    <button
      className={cx('intro-overlay', { 'is-hidden': introHidden })}
      onClick={() => onClick()}
      onWheel={() => onWheel()}
      aria-label="Open page"
    >
      <ShapeLogo />
    </button>
  )
}

export default IntroOverlay
