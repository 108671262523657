import React, { useEffect, useRef } from 'react'
import shuffleLetters from 'shuffle-letters'
import ImageLoader from './image-loader'

// transition timing
const aniInitialDelay = 200 // before type in
const shuffleAniDelay = 200 // * container index

const Release = props => {
  const {
    releaseId,
    title,
    artist,
    coverImage,
    tracks,
    link,
    aniDelayOffset,
    introHidden,
    mobView,
  } = props
  const containerRef = useRef()
  const innerRef = useRef()

  useEffect(() => {
    const innerEl = innerRef.current
    const els = innerEl.childNodes
    if (introHidden) {
      setTimeout(() => {
        els.forEach(el => {
          setTimeout(() => {
            el.style.opacity = 1
            shuffleLetters(el)
          }, shuffleAniDelay * aniDelayOffset)
        })
      }, aniInitialDelay)
    }
  }, [introHidden])

  return (
    <a className="release" href={link} target="_blank" rel="noopener noreferrer" ref={containerRef}>
      <div className="release__inner" ref={innerRef}>
        <h2>{`<${releaseId}>`}</h2>
        <p>
          {artist} - {title}
        </p>
        {tracks && tracks.map((track, i) => <p key={`release-track-${i}`}>{track}</p>)}
      </div>
      <div className="release__inner release__inner--placeholder">
        <h2>{`<${releaseId}>`}</h2>
        <p>
          {artist} - {title}
        </p>
        {tracks && tracks.map((track, i) => <p key={`release-placeholder-track-${i}`}>{track}</p>)}
      </div>
      {mobView && coverImage.asset && (
        <div className="release__cover">
          <ImageLoader
            imageData={coverImage}
            widths={[320, 375, 750, 1024]}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'contain',
            }}
            alt={`Cover image for <${releaseId}> ${artist} - ${title}`}
          />
        </div>
      )}
    </a>
  )
}

export default Release
