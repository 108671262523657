import React, { useState } from 'react'
import { buildSrcSetStr, buildPlaceholderUrl } from '../lib/helpers'
import 'lazysizes'

const ImageLoader = props => {
  const { className, imageData, widths, alt, style } = props
  return (
    <img
      data-sizes="auto"
      // src={buildPlaceholderUrl(imageData, 20)}
      data-srcset={buildSrcSetStr(imageData, widths)}
      className={`${className ? className : ''} image-loader lazyload fade-in`}
      style={{
        ...style,
      }}
      alt={alt ? alt : ''}
    />
  )
}

export default ImageLoader
